import React, { useState } from 'react'
import { Chart as ChartJs, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels';


function Chart(props: any) {
    const register = ChartJs.register(ArcElement, Tooltip, Legend)
    const label = ChartJs.register(ChartDataLabels)
    return (
        <div>
            <Pie data={props.data} />
        </div>
    )
}

export default Chart