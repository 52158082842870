import api from "./http";

export const ApiSimulasi = {
    getSimulasi : ()=>{
        return api.get(`/report`)
    },
    getTunjangan : ()=>{
        return api.get(`/jabatan`)
    },
    postTunjangan : (data:any)=>{
        return api.post(`/jabatan`,data)
    },
    getReset : ()=>{
        return api.get(`/report/reset`)
    },
    putTunjangan : (data:any)=>{
        return api.put(`/report`,data)
    },
    putPtk : (uuid:string,data:any) =>{
        return api.put(`/report/ptk/${uuid}`,data)
    },
    searchPtk : (key:string)=>{
        return api.get(`/report/ptk/search/${key}`)
    }

}