import React from 'react'

function Table(props: any) {
  return (
    <div>
      <div className='bg-white shadow-xl md:my-10 my-5 mx-5 p-4 '>
        {props.object !== null && props.object !== undefined ? Object?.keys(props?.object)?.length === 0 ?
          <div className='flex justify-center p-4'>
            <h1 className='text-2xl'>{props.msg}</h1>
          </div>
          :
          <div className=" overflow-x-auto shadow-md sm:rounded-lg my-7 h-[80vh]">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0 ">
                <tr>
                  {props?.head?.map((d: any, id: number) => (
                    <th key={id} scope="col" className="px-6 py-3">
                      {d}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {props.children}
              </tbody>
            </table>
          </div>
          :
          <></>
        }
      </div>
    </div>
  )
}

export default Table