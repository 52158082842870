import React, { useEffect, useState } from 'react'
import HeaderUi from '../templates/HeaderUi'
import Table from '../templates/Table'
import Input from '../component/fields/Input'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { useSelector } from 'react-redux'
import { allSimulasi, allTunjangan } from '../store/actions/simulasi'
import { currency } from '../component/Currency'
import { satkerOpt } from '../component/Satker'

function Juli() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { simulasiAll, tunjanganAll, simulasiFind, msgSimulasi, loadingSimulasi } = useSelector((state: RootState) => state.simulasi)
    const [head, setHead] = useState(["No", "Nupy", "Nama", "Satker", "Gender", "Mk", "Gol", "Gapok Lama", "Gapok Baru", "Selisih Gapok", "% Kenaikan Gapok", "Tunjangan Baru", "Tunjangan Lama", "Selisih Tunjangan", "Tugas Tambahan", "Ket Tugas Tambahan", "THP Realisasi Juli", "THP Lama", "% THP", "THP Juli"])
    const [showAlert, setShowAlert] = useState(false)
    const [key, setKey] = useState<any>(100)
    const [newTnpb, setNewTnpb] = useState([])
    const [total, setTotal] = useState(0)
    const [realisasi,setRealisasi] = useState(0)
    const [anggaranBulan,setAnggaranBulan] = useState(1073367000)
    const valKey = (e: any) => {
        if (parseInt(e.target.value) <= 100) {
            setKey(parseInt(e.target.value))
        }
        else {
            setKey("")
        }
    }
    useEffect(() => {
        if (showAlert) {
            const interval = setInterval(() => {
                setShowAlert(false)
            }, 2000)
            return () => clearInterval(interval)
        }
        else {
            dispatch(allTunjangan())
            dispatch(allSimulasi())
        }
    }, [showAlert])
    useEffect(() => {
        if (Object.keys(simulasiAll).length !== 0 && key > 0) {
            setNewTnpb([])
            let all = 0
            let tnpb: any = []
            let real = 0
            simulasiAll?.ptk?.map((d: any) => {
                real += d.takeHomePayBaru
                if (d.takeHomePayBaru > d.takeHomePayLama) {
                    tnpb.push((((d.takeHomePayBaru - d.takeHomePayLama) / 100) * parseInt(key))+d.takeHomePayLama)
                    all += (((d.takeHomePayBaru - d.takeHomePayLama) / 100) * parseInt(key))+d.takeHomePayLama
                }
                else {
                    tnpb.push(d.takeHomePayBaru)
                    all += d.takeHomePayBaru
                }
            })
            setNewTnpb(tnpb)
            setTotal(all)
            setRealisasi(real)
        }
    }, [key, simulasiAll])
    return (
        <div>
            <HeaderUi title="Simulasi bulan Juli" />
            <div className='bg-white p-4 mx-4 my-8'>
                <div className='mt-4'>
                    <Input type="number" label="Masukkan %" value={key} onChange={valKey} />
                </div>
                {/* <h1 className='mt-5 font-semibold text-lg text-blue-600'>Total THP Realisasi Juli : {currency(realisasi)}</h1> */}
                <h1 className='mt-5 font-semibold text-lg text-blue-600'>Total Realisasi Juli : {currency(total)}</h1>
                <h1 className='font-semibold text-lg text-blue-600'>Total Anggaran : {currency(anggaranBulan)}</h1>
                <h1 className=' font-semibold text-lg text-blue-600'>Selisih : {currency(anggaranBulan-total)}</h1>

                <Table
                    head={head}
                    object={simulasiAll}
                    msg="Tidak ada data"
                >
                    {simulasiAll?.ptk?.map((d: any, id: number) => (
                        <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                            <td className='px-6 py-3'>{id + 1}</td>
                            <td className='px-6 py-3'>{d.nupy}</td>
                            <td className='px-6 py-3'>{d.nama}</td>
                            <td className='px-6 py-3'>{satkerOpt[d.satker - 1]}</td>
                            <td className='px-6 py-3'>{d.gender}</td>
                            <td className='px-6 py-3'>{d.masaKerja}</td>
                            <td className='px-6 py-3'>{d.gol}</td>
                            <td className='px-6 py-3'>{d.gapokLama !== null && currency(d.gapokLama)}</td>
                            <td className='px-6 py-3'>{d.gapokBaru !== null && currency(d.gapokBaru)}</td>
                            <td className='px-6 py-3'>{d.selisihGapok !== null && currency(d.selisihGapok)}</td>
                            <td className='px-6 py-3'>{d.persentaseKenaikanGajiPokok}</td>
                            <td className='px-6 py-3'>{d.tunjanganJabatanBaru !== null && currency(d.tunjanganJabatanBaru)}</td>
                            <td className='px-6 py-3'>{d.tunjanganJabatanLama !== null && currency(d.tunjanganJabatanLama)}</td>
                            <td className='px-6 py-3'>{d.selisihTunjangan !== null && currency(d.selisihTunjangan)}</td>
                            <td className='px-6 py-3'>{d.tugasTambahan !== null && currency(d.tugasTambahan)}</td>
                            <td className='px-6 py-3'>{d.ketTugasTambahan}</td>
                            <td className='px-6 py-3'>{d.takeHomePayBaru !== null && currency(d.takeHomePayBaru)}</td>
                            <td className='px-6 py-3'>{d.takeHomePayLama !== null && currency(d.takeHomePayLama)}</td>
                            <td className='px-6 py-3'>{d.persentasiTHP}</td>
                            <td className='px-6 py-3'>{newTnpb.length !== 0 ? currency(newTnpb[id]) : currency(0)}</td>
                        </tr>
                    ))}
                </Table>
            </div>
        </div>
    )
}

export default Juli