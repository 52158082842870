import React, { useEffect } from 'react'
import Dialog from '../../templates/Dialog'
import Button from '../Button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import SelectorForm from '../fields/SelectorForm'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import InputForm from '../fields/InputForm'


function EditPtk(props: any) {
    const {tunjanganAll} = useSelector((state:RootState)=>state.simulasi)
    const methodEditPtk = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                golAwal : yup.string().required("tidak boleh kosong"),
                jabatanBaru : yup.string().required("tidak boleh kosong"),
                tugasTambahan : yup.number().required("tidak boleh kosong"),
                ketTugasTambahan : yup.string().required("tidak boleh kosong")
            })
        )
    })
    const tunjanganOpt = tunjanganAll.map((d:any)=>{
        return {value:d.kode,label:d.jabatan}
    })
    useEffect(() => {
        if (props.showForm && Object.keys(props.data)?.length !== 0) {
            methodEditPtk.reset({golAwal:props.data.gol,jabatanBaru:props.data.kodeTunjangan,tugasTambahan:props.data.tugasTambahan,ketTugasTambahan:props.data.ketTugasTambahan})
        }
    }, [props.showForm, props.data])
    return (
        <Dialog show={props.showForm} toggle={props.closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Edit Ptk</h1>
                </div>
                <form onSubmit={methodEditPtk.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    <InputForm label="Golongan Awal" method={methodEditPtk} methodName="golAwal"/>
                    <SelectorForm label="Jabatan Baru" method={methodEditPtk} methodName="jabatanBaru" options={tunjanganOpt}/>
                    <InputForm label="Tugas Tambahan" method={methodEditPtk} methodName="tugasTambahan"/>
                    <InputForm label="Keterangan Tugas Tambahan" method={methodEditPtk} methodName="ketTugasTambahan"/>
                    <div className='flex justify-end pt-4'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>)
}

export default EditPtk