import React from 'react';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import Simulasi from './UI/Simulasi';
import Sidebar from './templates/Sidebar';
import Settings from './UI/Settings';
import Juli from './UI/Juli';
import Login from './UI/Login';
import { RootState } from './store';
import { useSelector } from 'react-redux';

function App() {
  const {token} = useSelector((state:RootState)=>state.auth)
  return (
    <div>
      <Routes>
        <Route path='/' element={token !== "" ? <Navigate to={"/home/simulasi"} replace/> : <Login/>}/>
        <Route path='/home' element={token !== "" ? <Sidebar/> : <Navigate to={"/"} replace/>}>
          <Route path='/home/simulasi' element={<Simulasi/>}/>
          <Route path='/home/juli' element={<Juli/>}/>
          <Route path='/home/settings' element={<Settings/>}/>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
