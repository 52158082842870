export const satkerOpt = [
    "SDIT Putra",
    "SDIT Putri",
    "SMPIT Putra",
    "SMPIT Putri",
    "SMPIT Putra Fullday",
    "Diniyyah",
    "MA Plus",
    "SMAIT Putri",
    "SMAIT Putra Fullday",
    "I'dad Muallimat",
    "SDIT Sembalun",
    "PAUD",
    "PLS",
    "Asrama Putra",
    "Asrama Putri",
    "Kebendaharaan",
    "Pimpinan Pondok",
    "Unit Usaha",
    "Perpustakaan",
    "UKS",
    "BIDUM",
    "Sekretaris Umum",
    "Personalia & Humas",
    "Tim IT",
    "Keamanan",
    "Dept.Bahasa",
    "Dept.Tahfizh",
    "Dept.KSA",
    "Litbang",
    "Dewan Majlis",
    "Dewan Pengawas",
    "Dapur",
    "Devisi Dakwah"
]