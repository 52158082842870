import { createSlice } from "@reduxjs/toolkit";
import { isLogin, isLogout } from "../actions/auth";
import { Auth } from "../interfaces";

const initialState: Auth = {
    token: "",
    username: "",
    nama: "",
    role: "",
    sistem: "",
    super_admin: false,
    generalUser: false,
    loadingAuth: false,
    msgAuth: ""
}

export const authStore = createSlice({
    name: "auth",
    initialState,
    reducers: {
        clearAuth: (state) => {
            state.token = ""
            state.username= ""
            state.nama= ""
            state.role= ""
            state.sistem= ""
            state.super_admin= false
            state.generalUser= false
            state.loadingAuth= false
            state.msgAuth= ""
        }
    },
    extraReducers : builder =>{
        builder
        .addCase(isLogin.pending,(state)=>{
            state.loadingAuth = true
        })
        .addCase(isLogin.fulfilled,(state,action)=>{
            state.loadingAuth = false
            state.token = action.payload.token
            state.nama = action.payload.nama
            state.username = action.payload.username

        })
        .addCase(isLogin.rejected,(state,action)=>{
            state.loadingAuth = false
            state.msgAuth = action.payload?.message
        })
        .addCase(isLogout.pending,(state)=>{
            state.loadingAuth = true
        })
        .addCase(isLogout.fulfilled,(state)=>{
            state.loadingAuth = false
            state.nama = ""
            state.token = ""
            state.username = ""
        })
        .addCase(isLogout.rejected,(state)=>{
            state.loadingAuth = false
            state.nama = ""
            state.token = ""
            state.username = ""
        })
    }
})
export const authAction = authStore.actions
export default authStore.reducer