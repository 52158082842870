import React, { useEffect, useState } from 'react'
import { HiOutlineCircleStack, HiOutlineListBullet, HiOutlineXMark } from "react-icons/hi2"
import { IoSettingsSharp } from "react-icons/io5"
import { Link, Outlet, useLocation } from 'react-router-dom'
import Alert from './Alert'
import Loading from './Loading'
import { isLogout } from '../store/actions/auth'
import {BsDoorOpen} from "react-icons/bs"
import { TypeDispatch, useAppDispatch } from '../store'

function Sidebar() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const [sideMenu, setSideMenu] = useState<boolean>(false)
    const [dropUser, setDropUser] = useState<boolean>(false)
    const [refrensiDrop, setRefrensiDrop] = useState<boolean>(false)
    const [alertUser, setAlertUser] = useState<boolean>(false)
    const location = useLocation()
    const date = new Date()
    const tahun = date.getFullYear()
    const out = () => {
        dispatch(isLogout())
    }
    useEffect(() => {
        if (alertUser) {
            const interval = setInterval(() => {
                setAlertUser(false)
            }, 2000)
            return () => clearInterval(interval)
        }
    }, [alertUser])
    return (
        <div className=''>
            {/* <Loading show={loadingUser} />
            <Alert show={alertUser} msg={msgUser} />
            <EditUserSelf showForm={showFormUser} closeForm={setShowFormUser} submit={editUser} /> */}
            <header className='bg-[#1e81b0] py-4 md:px-5 px-4 flex justify-between  w-full'>
                <div className='flex md:space-x-2 space-x-4'>
                    <HiOutlineListBullet onClick={() => setSideMenu(!sideMenu)} className='md:w-9 md:h-9 w-8 h-8 my-auto block bg-slate-200 md:mt-1 rounded-full p-1 cursor-pointer' />
                    <div className='flex items-center md:mt-1'>
                        <h1 className='text-3xl text-white my-auto'>Statisik</h1>
                    </div>
                </div>
            </header>
            <div className='flex' >
                <div className={`md:static fixed md:top-0 top-0 bg-slate-50 md:h-[91vh] h-screen  w-[85vw] md:z-0 z-10 overflow-y-hidden hover:overflow-y-auto transition-all ease-in-out duration-300 border-r-2 ${sideMenu ? "left-0 md:w-[30vw]" : "-left-[85vw] md:w-0"}`}>
                    <div className='w-full flex justify-end p-3 md:hidden'>
                        <HiOutlineXMark onClick={() => setSideMenu(!sideMenu)} className='w-7 h-7' />
                    </div>
                    <div className='md:my-1 flex'>
                        {/* <HiUser className='w-12 h-12 bg-white rounded-full p-2 my-auto' />
                        <div>
                            <h1 className='mx-3 text-lg font-light'>{}</h1>
                        </div> */}
                    </div>
                    <h1 className='px-6 text-xl text-gray-500 font-light my-2'>Menu</h1>
                    <Link onClick={() => setSideMenu(!sideMenu)} to={`/home/simulasi`} className={`flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${location.pathname === "/dashboard" ? "border-l-4 border-sky-500 text-sky-700" : "border-l-4 border-transparent"}`}>
                        <div className='my-auto' >
                            <HiOutlineCircleStack className="w-6 h-6 mx-2" />
                        </div>
                        <h1 className='text-lg'>Simulasi</h1>
                    </Link>
                    <Link onClick={() => setSideMenu(!sideMenu)} to={`/home/juli`} className={`flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${location.pathname === "/dashboard" ? "border-l-4 border-sky-500 text-sky-700" : "border-l-4 border-transparent"}`}>
                        <div className='my-auto' >
                            <IoSettingsSharp className="w-6 h-6 mx-2" />
                        </div>
                        <h1 className='text-lg'>Juli</h1>
                    </Link>
                    <Link onClick={() => setSideMenu(!sideMenu)} to={`/home/settings`} className={`flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${location.pathname === "/dashboard" ? "border-l-4 border-sky-500 text-sky-700" : "border-l-4 border-transparent"}`}>
                        <div className='my-auto' >
                            <IoSettingsSharp className="w-6 h-6 mx-2" />
                        </div>
                        <h1 className='text-lg'>Settings</h1>
                    </Link>
                    <div onClick={out} className={`flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${location.pathname === "/dashboard" ? "border-l-4 border-sky-500 text-sky-700" : "border-l-4 border-transparent"}`}>
                        <div className='my-auto' >
                            <BsDoorOpen className="w-6 h-6 mx-2" />
                        </div>
                        <h1 className='text-lg'>Log Out</h1>
                    </div>
                    {/* <h1 className='px-6 text-xl text-gray-500 font-light my-2'>Santri</h1> */}
                    {/* {menuSantri.map((e, id) => (
                        <Link onClick={() => setSideMenu(!sideMenu)} to={`${e.link}`} key={id} className={`flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${location.pathname === e.link ? "border-l-4 border-sky-500 text-sky-700" : "border-l-4 border-transparent"}`}>
                            <div className='my-auto' >
                                {e.icon}
                            </div>
                            <h1 className='text-lg'>{e.title}</h1>
                        </Link>
                    ))} */}
                    {/* <h1 className={`px-6 text-xl text-gray-500 font-light my-2 ${super_admin || generalUser ? "block" : "hidden"}`}>Lainnya</h1> */}
                    {/* {menuPanel.map((e: any, id: any) => (
                        <div className={`${super_admin || generalUser ? "block" : "hidden"}`} key={id} onClick={() => {
                            setDropUser(false)
                        }}>
                            {e.drop ?
                                <div key={id}  >
                                    <div onClick={() => setRefrensiDrop(!refrensiDrop)} className={`flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 cursor-pointer border-l-4 border-transparent `}>
                                        <div className='my-auto'>
                                            {e.logo}
                                        </div>
                                        <h1 className={`text-lg`}>{e.title}</h1>
                                        <div className='flex justify-end w-full my-auto'>
                                            {e.dropIcon}
                                        </div>
                                    </div>
                                    <div className={refrensiDrop ? "block" : "hidden"}>
                                        {e.menuDrop.map((d: any, ind: any) => (
                                            <Link onClick={() => setSideMenu(!sideMenu)} to={`${d.link}`} key={ind} className={`flex hover:text-sky-600  py-2 px-9 font-light transition-colors ease-in-out duration-300 ${location.pathname === d.link ? "border-l-4 border-sky-500 text-sky-700" : "border-l-4 border-transparent"}`}>
                                                <h1 className={`text-lg`}>{d.title}</h1>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                                :
                                <Link onClick={() => setSideMenu(!sideMenu)} to={`${e.link}`} key={id} className={`flex hover:text-sky-600  py-2 px-3 font-light transition-colors ease-in-out duration-300 ${location.pathname === e.link && e.drop !== refrensiDrop ? "border-l-4 border-sky-500 text-sky-700" : "border-l-4 border-transparent"} `}>
                                    <div className='my-auto '>
                                        {e.icons}
                                    </div>
                                    <h1 className='text-lg'>{e.title}</h1>
                                </Link>
                            }
                        </div>
                    ))} */}
                </div>
                <div className='md:w-[100vw] w-full relative bg-slate-100 h-[90vh] overflow-y-auto' onClick={() => setDropUser(false)}>
                    <Outlet />
                    <footer className=' p-3 flex space-x-2'>
                        <h1 className='font-light md:text-xl'>COPYRIGHT &#9400; {tahun}</h1>
                        <h1 className='font-extralight md:text-xl text-gray-900'>Ponpes Abu Hurairah.</h1>
                    </footer>
                </div>
                <div onClick={() => setSideMenu(!sideMenu)} className={`bg-black bg-opacity-80 fixed top-0 left-0 w-screen h-screen md:hidden ${sideMenu ? "block" : "hidden"}`}>
                </div>
            </div>
        </div>)
}

export default Sidebar