import React, { useEffect, useState } from 'react'
import HeaderUi from '../templates/HeaderUi'
import Table from '../templates/Table'
import Chart from '../templates/Chart'
import 'chartjs-plugin-datalabels';
import { RootState, TypeDispatch, useAppDispatch } from '../store';
import { useSelector } from 'react-redux';
import { allSimulasi } from '../store/actions/simulasi';
import { currency } from '../component/Currency';
import { satkerOpt } from '../component/Satker';

function Simulasi() {
  const dispatch: TypeDispatch<any> = useAppDispatch()
  const { simulasiAll } = useSelector((state: RootState) => state.simulasi)
  const [showChart, setShowChart] = useState<boolean>(false)
  const [showTable, setShowTable] = useState<boolean>(false)
  const [showRekap, setShowRekap] = useState<boolean>(false)
  const [baru, setBaru] = useState<number>(0)
  const [lama, setLama] = useState<number>(0)
  const [head, setHead] = useState(["No", "Nupy", "Nama", "Satker", "Gender", "Mk", "Gol", "Gapok Lama", "Gapok Baru", "Selisih Gapok", "% Kenaikan Gapok", "Tunjangan Baru", "Tunjangan Lama", "Selisih Tunjangan", "THP Baru", "THP Lama", "% THP"])
  
  const [pie, setPie] = useState({
    labels: ['THP Baru', 'THP Lama'],
    datasets: [
      {
        label: '',
        data: [0, 0],
        offset: 7,
        backgroundColor: [
          'rgba(0, 82, 155)',
          'rgba(244, 122, 31)',
        ],
        borderColor: [
          'rgba(0, 82, 155)',
          'rgba(244, 122, 31)',
        ],
        borderWidth: 1,
        datalabels: {
          formatter: function (val: any) {
            return currency(val)
          },
          color: 'White',
          font: {
            weight: "bold",
            size: "20px"
          }
        }

      },

    ],
  })
  useEffect(() => {
    setPie({
      labels: ['THP Baru', 'THP Lama'],
      datasets: [
        {
          label: '',
          data: [baru, lama],
          offset: 7,
          backgroundColor: [
            'rgba(0, 82, 155)',
            'rgba(244, 122, 31)',
          ],
          borderColor: [
            'rgba(0, 82, 155)',
            'rgba(244, 122, 31)',
          ],
          borderWidth: 1,
          datalabels: {
            formatter: function (val: any) {
              return currency(val)
            },
            color: 'White',
            font: {
              weight: "bold",
              size: "20px"
            },
          },

        },
      ],
    })
  }, [baru, lama])
  useEffect(() => {
    dispatch(allSimulasi())
  }, [])
  useEffect(() => {
    if (simulasiAll?.baru?.totalTHPBaru !== null && simulasiAll?.baru?.totalTHPBaru !== undefined) {
      setBaru(simulasiAll?.baru?.totalTHPBaru)
    }
    if (simulasiAll?.lama?.totalTHPLama !== null && simulasiAll?.lama?.totalTHPLama !== undefined) {
      setLama(simulasiAll?.lama?.totalTHPLama)
    }
  }, [simulasiAll])
  return (
    <div>
      <HeaderUi title="Simulasi" />
      <div className='bg-white shadow-xl md:my-10 my-5 mx-5 p-8 '>
        <h1 className='mb-3 text-2xl'>Data Diagram</h1>
        <div className={`${showChart ? "mb-3" : "mb-0"}`}>
          <button onClick={() => {
            setShowTable(false)
            setShowRekap(false)
            setShowChart(!showChart)
          }} className='px-10 py-2 border border-blue-700 text-blue-800 rounded-md hover:bg-blue-700 hover:text-white transition-all ease-in-out duration-300'>{showChart ? "Tutup" : "Tampilkan"}</button>
        </div>
        <div className={`flex flex-col justify-between items-center ${showChart ? "block" : "hidden"}`}>
          <div className={`md:w-[40%] md:h-[40%] w-[90%] h-[90%]`}>
            <Chart data={pie} />
          </div>
          <div className='flex flex-col overflow-x-auto'>
            <div className='shadow-md my-3'>
              <h1 className='my-2 text-xl'>Baru</h1>
              <div className='grid md:grid-cols-10 gap-4 p-2 text-center'>
                <h1 className='px-3'>THP Baru</h1>
                <h1 className='px-3'>Bonus Akhir Tahun</h1>
                <h1 className='px-3'>Tunjangan Hari Tua</h1>
                <h1 className='px-3'>Tunjangan Umum</h1>
                <h1 className='px-3'>Gaji Satpam Depan</h1>
                <h1 className='px-3'>Gaji Paud</h1>
                <h1 className='px-3'>Kinerja PTK</h1>
                <h1 className='px-3'>Total</h1>
                <h1 className='px-3'>Anggaran</h1>
                <h1 className='px-3'>Selisih</h1>
              </div>
              <div className='grid md:grid-cols-10 gap-4 p-2 '>
                <h1 className='px-3'>{simulasiAll?.baru?.totalTHPBaru !== null && simulasiAll?.baru?.totalTHPBaru !== undefined ? currency(simulasiAll?.baru?.totalTHPBaru) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.baru?.bonusAkhirTahun !== null && simulasiAll?.baru?.bonusAkhirTahun !== undefined ? currency(simulasiAll?.baru?.bonusAkhirTahun) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.baru?.tunjanganHariTua !== null && simulasiAll?.baru?.tunjanganHariTua !== undefined ? currency(simulasiAll?.baru?.tunjanganHariTua) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.baru?.tunjanganUmum !== null && simulasiAll?.baru?.tunjanganUmum !== undefined ? currency(simulasiAll?.baru?.tunjanganUmum) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.baru?.gajiSatpamDepan !== null && simulasiAll?.baru?.gajiSatpamDepan !== undefined ? currency(simulasiAll?.baru?.gajiSatpamDepan) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.baru?.gajiPaud !== null && simulasiAll?.baru?.gajiPaud !== undefined ? currency(simulasiAll?.baru?.gajiPaud) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.baru?.kinerjaPTK !== null && simulasiAll?.baru?.kinerjaPTK !== undefined ? currency(simulasiAll?.baru?.kinerjaPTK) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.baru?.total !== null && simulasiAll?.baru?.total !== undefined ? currency(simulasiAll?.baru?.total) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.baru?.anggaran !== null && simulasiAll?.baru?.anggaran !== undefined ? currency(simulasiAll?.baru?.anggaran) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.baru?.selisih !== null && simulasiAll?.baru?.selisih !== undefined ? currency(simulasiAll?.baru?.selisih) : 0}</h1>
              </div>
            </div>
            <div>
              <h1 className='my-2 text-xl'>Lama</h1>
              <div className='grid md:grid-cols-10 gap-4 shadow-md p-2 text-center'>
                <h1 className='px-3'>THP Lama</h1>
                <h1 className='px-3'>Bonus Akhir Tahun</h1>
                <h1 className='px-3'>Tunjangan Hari Tua</h1>
                <h1 className='px-3'>Tunjangan Umum</h1>
                <h1 className='px-3'>Gaji Satpam Depan</h1>
                <h1 className='px-3'>Gaji Paud</h1>
                <h1 className='px-3'>Kinerja PTK</h1>
                <h1 className='px-3'>Total</h1>
                <h1 className='px-3'>Anggaran</h1>
                <h1 className='px-3'>Selisih</h1>
              </div>
              <div className='grid md:grid-cols-10 gap-4 p-2 '>
                <h1 className='px-3'>{simulasiAll?.lama?.totalTHPLama !== null && simulasiAll?.lama?.totalTHPLama !== undefined ? currency(simulasiAll?.lama?.totalTHPLama) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.lama?.bonusAkhirTahun !== null && simulasiAll?.lama?.bonusAkhirTahun !== undefined ? currency(simulasiAll?.lama?.bonusAkhirTahun) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.lama?.tunjanganHariTua !== null && simulasiAll?.lama?.tunjanganHariTua !== undefined ? currency(simulasiAll?.lama?.tunjanganHariTua) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.lama?.tunjanganUmum !== null && simulasiAll?.lama?.tunjanganUmum !== undefined ? currency(simulasiAll?.lama?.tunjanganUmum) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.lama?.gajiSatpamDepan !== null && simulasiAll?.lama?.gajiSatpamDepan !== undefined ? currency(simulasiAll?.lama?.gajiSatpamDepan) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.lama?.gajiPaud !== null && simulasiAll?.lama?.gajiPaud !== undefined ? currency(simulasiAll?.lama?.gajiPaud) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.lama?.kinerjaPTK !== null && simulasiAll?.lama?.kinerjaPTK !== undefined ? currency(simulasiAll?.lama?.kinerjaPTK) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.lama?.total !== null && simulasiAll?.lama?.total !== undefined ? currency(simulasiAll?.lama?.total) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.lama?.anggaran !== null && simulasiAll?.lama?.anggaran !== undefined ? currency(simulasiAll?.lama?.anggaran) : 0}</h1>
                <h1 className='px-3'>{simulasiAll?.lama?.selisih !== null && simulasiAll?.lama?.selisih !== undefined ? currency(simulasiAll?.lama?.selisih) : 0}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-white shadow-xl md:my-10 my-5 mx-5 p-8 '>
        <h1 className='mb-3 text-2xl'>Data Table</h1>
        <div className={`${showChart ? "mb-3" : "mb-0"}`}>
          <button onClick={() => {
            setShowRekap(false)
            setShowChart(false)
            setShowTable(!showTable)
          }}
            className='px-10 py-2 border border-blue-700 text-blue-800 rounded-md hover:bg-blue-700 hover:text-white transition-all ease-in-out duration-300'>{showTable ? "Tutup" : "Tampilkan"}</button>
        </div>
        <div className={`${showTable ? "visible" : "hidden"}`}>
          <Table
            head={head}
            object={simulasiAll}
            msg="Tidak ada data"
          >
            {simulasiAll?.ptk?.map((d: any, id: number) => (
              <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                <td className='px-6 py-3'>{id + 1}</td>
                <td className='px-6 py-3'>{d.nupy}</td>
                <td className='px-6 py-3'>{d.nama}</td>
                <td className='px-6 py-3'>{satkerOpt[d.satker-1]}</td>
                <td className='px-6 py-3'>{d.gender}</td>
                <td className='px-6 py-3'>{d.masaKerja}</td>
                <td className='px-6 py-3'>{d.gol}</td>
                <td className='px-6 py-3'>{d.gapokLama !== null && currency(d.gapokLama)}</td>
                <td className='px-6 py-3 font-semibold'>{d.gapokBaru !== null && currency(d.gapokBaru)}</td>
                <td className='px-6 py-3'>{d.selisihGapok !== null && currency(d.selisihGapok)}</td>
                <td className='px-6 py-3'>{d.persentaseKenaikanGajiPokok}</td>
                <td className='px-6 py-3'>{d.tunjanganJabatanBaru !== null && currency(d.tunjanganJabatanBaru)}</td>
                <td className='px-6 py-3 font-semibold'>{d.tunjanganJabatanLama !== null && currency(d.tunjanganJabatanLama)}</td>
                <td className='px-6 py-3'>{d.selisihTunjangan !== null && currency(d.selisihTunjangan)}</td>
                <td className='px-6 py-3 font-semibold'>{d.takeHomePayBaru !== null && currency(d.takeHomePayBaru)}</td>
                <td className='px-6 py-3'>{d.takeHomePayLama !== null && currency(d.takeHomePayLama)}</td>
                <td className='px-6 py-3'>{d.persentasiTHP}</td>
              </tr>
            ))}
          </Table>
        </div>
      </div>
    </div>
  )
}

export default Simulasi