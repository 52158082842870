import { createSlice } from "@reduxjs/toolkit";
import { Simulasi } from "../interfaces";
import { addTunjangan, allSimulasi, allTunjangan, findSimulasi, resetData, updatePtk, updateTunjangan } from "../actions/simulasi";


const initialState : Simulasi = {
    simulasiAll :[],
    simulasiFind : [],
    tunjanganAll:[],
    simulasiOne :{},
    loadingSimulasi : false,
    msgSimulasi : ""
}

export const simulasiStore = createSlice({
    name:"simulasi",
    initialState,
    reducers:{
        clearSimulasi : (state) =>{
            state.simulasiFind = []
            state.simulasiAll = []
            state.msgSimulasi = ""
        }
    },
    extraReducers: builder =>{
        builder
        .addCase(allSimulasi.pending,(state)=>{
            state.loadingSimulasi =true
        })
        .addCase(allSimulasi.fulfilled,(state,action)=>{
            state.loadingSimulasi = false
            state.simulasiAll = action.payload
        })
        .addCase(allSimulasi.rejected,(state,action)=>{
            state.loadingSimulasi = false
            state.msgSimulasi = action.payload?.message
        })
        .addCase(findSimulasi.pending,(state)=>{
            state.loadingSimulasi =true
        })
        .addCase(findSimulasi.fulfilled,(state,action)=>{
            state.loadingSimulasi = false
            state.simulasiFind = action.payload
        })
        .addCase(findSimulasi.rejected,(state,action)=>{
            state.loadingSimulasi = false
            state.msgSimulasi = action.payload?.message
        })
        .addCase(allTunjangan.pending,(state)=>{
            state.loadingSimulasi =true
        })
        .addCase(allTunjangan.fulfilled,(state,action)=>{
            state.loadingSimulasi = false
            state.tunjanganAll = action.payload
        })
        .addCase(allTunjangan.rejected,(state,action)=>{
            state.loadingSimulasi = false
            state.msgSimulasi = action.payload?.message
        })

        .addCase(resetData.pending,(state)=>{
            state.loadingSimulasi =true
        })
        .addCase(resetData.fulfilled,(state,action)=>{
            state.loadingSimulasi = false
            state.simulasiAll = action.payload
        })
        .addCase(resetData.rejected,(state,action)=>{
            state.loadingSimulasi = false
            state.msgSimulasi = action.payload?.message
        })
        .addCase(updateTunjangan.pending,(state)=>{
            state.loadingSimulasi =true
        })
        .addCase(updateTunjangan.fulfilled,(state,action)=>{
            state.loadingSimulasi = false
            state.msgSimulasi = action.payload.msg
        })
        .addCase(updateTunjangan.rejected,(state,action)=>{
            state.loadingSimulasi = false
            state.msgSimulasi = action.payload?.message
        })
        .addCase(updatePtk.pending,(state)=>{
            state.loadingSimulasi =true
        })
        .addCase(updatePtk.fulfilled,(state,action)=>{
            state.loadingSimulasi = false
            state.msgSimulasi = action.payload.msg
        })
        .addCase(updatePtk.rejected,(state,action)=>{
            state.loadingSimulasi = false
            state.msgSimulasi = action.payload?.message
        })
        .addCase(addTunjangan.pending,(state)=>{
            state.loadingSimulasi =true
        })
        .addCase(addTunjangan.fulfilled,(state,action)=>{
            state.loadingSimulasi = false
            state.msgSimulasi = action.payload.msg
        })
        .addCase(addTunjangan.rejected,(state,action)=>{
            state.loadingSimulasi = false
            state.msgSimulasi = action.payload?.message
        })

    }
})
export const simulasiActions = simulasiStore.actions
export default simulasiStore.reducer