import { AnyAction, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk, { ThunkDispatch } from "redux-thunk";
import simulasiStore from "./slice/simulasi";
import persistReducer from "redux-persist/es/persistReducer";
import authStore from "./slice/auth";


export const store = configureStore({
    reducer: {
        auth : persistReducer({
            key:"simulasi",
            storage
        },authStore),
        simulasi: simulasiStore
    },
    middleware: [thunk]
})
export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type TypeDispatch<T> = ThunkDispatch<T, any, AnyAction>
export const useAppDispatch = () => useDispatch<TypeDispatch<RootState>>()
