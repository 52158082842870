import { createAsyncThunk } from "@reduxjs/toolkit"
import { ErrorData } from "../interfaces"
import { ApiSimulasi } from "../../api/simulasi"


export const allSimulasi = createAsyncThunk<[],void,{rejectValue:ErrorData}>(
    'simulasi/get/all',
    async(_,{rejectWithValue})=>{
        try{
            const res : any =  await ApiSimulasi.getSimulasi()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    
    }
)
export const findSimulasi = createAsyncThunk<[],string,{rejectValue:ErrorData}>(
    'simulasi/get/find',
    async(key:string,{rejectWithValue})=>{
        try{
            const res : any =  await ApiSimulasi.searchPtk(key)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    
    }
)

export const allTunjangan = createAsyncThunk<[],void,{rejectValue:ErrorData}>(
    'tunjangan/get/all',
    async(_,{rejectWithValue})=>{
        try{
            const res : any =  await ApiSimulasi.getTunjangan()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    
    }
)
export const addTunjangan = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'tunjangan/post',
    async(data:any,{rejectWithValue})=>{
        try{
            const res : any =  await ApiSimulasi.postTunjangan(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    
    }
)

export const resetData = createAsyncThunk<any,void,{rejectValue:ErrorData}>(
    'simulasi/reset',
    async(_,{rejectWithValue})=>{
        try{
            const res :any =await ApiSimulasi.getReset()
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const updateTunjangan = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'simulasi/edit/tunjangan',
    async(data:any,{rejectWithValue})=>{
        try{
            const res :any = await ApiSimulasi.putTunjangan(data)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)
export const updatePtk = createAsyncThunk<any,any,{rejectValue:ErrorData}>(
    'simulasi/edit/ptk',
    async(data:any,{rejectWithValue})=>{
        try{
            const res :any = await ApiSimulasi.putPtk(data.uuid,data.update)
            if(res.status === 200){
                return res.data
            }
        }
        catch(err:any){
            return rejectWithValue({message:err.response.data.msg})
        }
    }
)