import axios from "axios";
import { isLogout } from "../store/actions/auth";


const api = axios.create({
    // baseURL:"http://192.168.1.6:9933"
    // baseURL:"http://192.168.50.232:9933"
    baseURL:"https://api-simulasi.ponpesabuhurairah.id"
})

let store:any;
export const injectStore = (_store: any) => {
  store = _store
}

api.interceptors.request.use((req:any) => {
    if (!req.url.includes("/user/login")) {
      req.headers = {
        Authorization: `Bearer ${store.getState().auth.token}`,
      };
    }
  return req;
});

api.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 401 && store.getState().auth.token !== "") {
     store.dispatch(isLogout())
    }
    return Promise.reject(err);
  }
);



export default api