import React, { useEffect, useState } from 'react'
import HeaderUi from '../templates/HeaderUi'
import Loading from '../templates/Loading'
import Table from '../templates/Table'
import { RootState, TypeDispatch, useAppDispatch } from '../store'
import { useSelector } from 'react-redux'
import { addTunjangan, allSimulasi, allTunjangan, findSimulasi, resetData, updatePtk, updateTunjangan } from '../store/actions/simulasi'
import { currency } from '../component/Currency'
import { HiOutlinePencilSquare } from 'react-icons/hi2'
import EditTunjangan from '../component/form/EditTunjangan'
import Alert from '../templates/Alert'
import { satkerOpt } from '../component/Satker'
import EditPtk from '../component/form/EditPtk'
import AddTunjangan from '../component/form/AddTunjangan'
import { simulasiActions } from '../store/slice/simulasi'
import Input from '../component/fields/Input'

function Settings() {
    const dispatch: TypeDispatch<any> = useAppDispatch()
    const { simulasiAll, tunjanganAll,simulasiFind, msgSimulasi, loadingSimulasi } = useSelector((state: RootState) => state.simulasi)
    const [showFormEditTunjangan, setShowFormEditTunjangan] = useState<boolean>(false)
    const [showFormEditPtk, setShowFormEditPtk] = useState<boolean>(false)
    const [showFormAddTunjangan, setShowFormAddTunjangan] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState(false)
    const [kode, setKode] = useState<string>("")
    const [key, setKey] = useState<string>("")
    const [saveOne, setSaveOne] = useState<any>({})
    const [head, setHead] = useState(["No", "Nupy", "Nama", "Satker", "Gender", "Mk", "Gol", "Gapok Lama", "Gapok Baru", "Selisih Gapok", "% Kenaikan Gapok", "Tunjangan Baru", "Tunjangan Lama", "Selisih Tunjangan","Tugas Tambahan","Ket Tugas Tambahan", "THP Baru", "THP Lama", "% THP", "Edit"])
    const ubahTunjangan = (e: any) => {
        dispatch(updateTunjangan(e))
        setShowFormEditTunjangan(false)
        setShowAlert(true)
    }
    const editPtk = (e: any) => {
        dispatch(updatePtk({ uuid: saveOne.uuid, update: e }))
        setShowFormEditPtk(false)
        setShowAlert(true)
    }
    const tambahJabatan = (e: any) => {
        dispatch(addTunjangan(e))
        setShowFormAddTunjangan(false)
        setShowAlert(true)

    }
    useEffect(() => {
        if (showAlert) {
            const interval = setInterval(() => {
                setShowAlert(false)
            }, 2000)
            return () => clearInterval(interval)
        }
        else {
            dispatch(allTunjangan())
            if (key.length > 3) {
                dispatch(simulasiActions.clearSimulasi())
                dispatch(findSimulasi(key))
            }
            else{
                dispatch(simulasiActions.clearSimulasi())
                dispatch(allSimulasi())
            }
            }
    }, [showAlert,key])
    return (
        <div>
            <Loading show={loadingSimulasi} />
            <Alert show={showAlert} msg={msgSimulasi} />
            <AddTunjangan showForm={showFormAddTunjangan} closeForm={setShowFormAddTunjangan} submit={tambahJabatan} />
            <EditTunjangan showForm={showFormEditTunjangan} closeForm={setShowFormEditTunjangan} submit={ubahTunjangan} />
            <EditPtk showForm={showFormEditPtk} closeForm={setShowFormEditPtk} submit={editPtk} data={saveOne} />
            <HeaderUi title="Settings" />
            <div className='bg-white p-4 mx-4 my-8'>
                <div className='space-x-4'>
                    <button onClick={() => {
                        dispatch(resetData())
                        setShowAlert(true)
                    }} className='px-10 py-2 border border-blue-700 text-blue-800 rounded-md hover:bg-blue-700 hover:text-white transition-all ease-in-out duration-300'>Reset</button>
                    <button onClick={() => {
                        setShowFormEditTunjangan(!showFormEditTunjangan)
                    }} className='px-10 py-2 border border-blue-700 text-blue-800 rounded-md hover:bg-blue-700 hover:text-white transition-all ease-in-out duration-300'>Edit Tunjangan</button>
                    <button onClick={() => {
                        setShowFormAddTunjangan(!showFormAddTunjangan)
                    }} className='px-10 py-2 border border-blue-700 text-blue-800 rounded-md hover:bg-blue-700 hover:text-white transition-all ease-in-out duration-300'>Tambah Jabatan</button>

                </div>
                <div className='mt-4'>
                    <Input label="Masukkan nama ptk" value={key} onChange={(e: any) => setKey(e.target.value)} />
                </div>
                <Table
                    head={head}
                    object={simulasiAll}
                    msg="Tidak ada data"
                >
                    {key.length > 3 ?
                        simulasiFind?.map((d: any, id: number) => (
                            <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                                <td className='px-6 py-3'>{id + 1}</td>
                                <td className='px-6 py-3'>{d.nupy}</td>
                                <td className='px-6 py-3'>{d.nama}</td>
                                <td className='px-6 py-3'>{satkerOpt[d.satker - 1]}</td>
                                <td className='px-6 py-3'>{d.gender}</td>
                                <td className='px-6 py-3'>{d.masaKerja}</td>
                                <td className='px-6 py-3'>{d.gol}</td>
                                <td className='px-6 py-3'>{d.gapokLama !== null && currency(d.gapokLama)}</td>
                                <td className='px-6 py-3'>{d.gapokBaru !== null && currency(d.gapokBaru)}</td>
                                <td className='px-6 py-3'>{d.selisihGapok !== null && currency(d.selisihGapok)}</td>
                                <td className='px-6 py-3'>{d.persentaseKenaikanGajiPokok}</td>
                                <td className='px-6 py-3'>{d.tunjanganJabatanBaru !== null && currency(d.tunjanganJabatanBaru)}</td>
                                <td className='px-6 py-3'>{d.tunjanganJabatanLama !== null && currency(d.tunjanganJabatanLama)}</td>
                                <td className='px-6 py-3'>{d.selisihTunjangan !== null && currency(d.selisihTunjangan)}</td>
                                <td className='px-6 py-3'>{d.tugasTambahan !== null && currency(d.tugasTambahan)}</td>
                                <td className='px-6 py-3'>{d.ketTugasTambahan}</td>
                                <td className='px-6 py-3'>{d.takeHomePayBaru !== null && currency(d.takeHomePayBaru)}</td>
                                <td className='px-6 py-3'>{d.takeHomePayLama !== null && currency(d.takeHomePayLama)}</td>
                                <td className='px-6 py-3'>{d.persentasiTHP}</td>
                                <td className='px-6 py-3'><HiOutlinePencilSquare className='w-6 h-6 cursor-pointer' onClick={() => {
                                    setShowFormEditPtk(!showFormEditPtk)
                                    setSaveOne(d)
                                }
                                } /></td>
                            </tr>
                        ))
                        :
                        simulasiAll?.ptk?.map((d: any, id: number) => (
                            <tr className="bg-white border-b hover:bg-gray-100 overflow-y-auto" key={id}>
                                <td className='px-6 py-3'>{id + 1}</td>
                                <td className='px-6 py-3'>{d.nupy}</td>
                                <td className='px-6 py-3'>{d.nama}</td>
                                <td className='px-6 py-3'>{satkerOpt[d.satker - 1]}</td>
                                <td className='px-6 py-3'>{d.gender}</td>
                                <td className='px-6 py-3'>{d.masaKerja}</td>
                                <td className='px-6 py-3'>{d.gol}</td>
                                <td className='px-6 py-3'>{d.gapokLama !== null && currency(d.gapokLama)}</td>
                                <td className='px-6 py-3'>{d.gapokBaru !== null && currency(d.gapokBaru)}</td>
                                <td className='px-6 py-3'>{d.selisihGapok !== null && currency(d.selisihGapok)}</td>
                                <td className='px-6 py-3'>{d.persentaseKenaikanGajiPokok}</td>
                                <td className='px-6 py-3'>{d.tunjanganJabatanBaru !== null && currency(d.tunjanganJabatanBaru)}</td>
                                <td className='px-6 py-3'>{d.tunjanganJabatanLama !== null && currency(d.tunjanganJabatanLama)}</td>
                                <td className='px-6 py-3'>{d.selisihTunjangan !== null && currency(d.selisihTunjangan)}</td>
                                <td className='px-6 py-3'>{d.tugasTambahan !== null && currency(d.tugasTambahan)}</td>
                                <td className='px-6 py-3'>{d.ketTugasTambahan}</td>
                                <td className='px-6 py-3'>{d.takeHomePayBaru !== null && currency(d.takeHomePayBaru)}</td>
                                <td className='px-6 py-3'>{d.takeHomePayLama !== null && currency(d.takeHomePayLama)}</td>
                                <td className='px-6 py-3'>{d.persentasiTHP}</td>
                                <td className='px-6 py-3'><HiOutlinePencilSquare className='w-6 h-6 cursor-pointer' onClick={() => {
                                    setShowFormEditPtk(!showFormEditPtk)
                                    setSaveOne(d)
                                }
                                } /></td>
                            </tr>
                        ))
                    }
                </Table>
            </div>
        </div>
    )
}

export default Settings