import React, { useEffect } from 'react'
import Dialog from '../../templates/Dialog'
import Button from '../Button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import SelectorForm from '../fields/SelectorForm'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import InputForm from '../fields/InputForm'

function AddTunjangan(props:any) {
    const methodAddTunjangan = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                jabatan: yup.string().required("tidak boleh kosong"),
                nominal: yup.string().required("tidak boleh kosong"),
            })
        )
    })
    useEffect(()=>{
        if(props.showForm){
            methodAddTunjangan.reset()
        }
    },[props.showForm])
    return (
        <Dialog show={props.showForm} toggle={props.closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Tambah Jabatan</h1>
                </div>
                <form onSubmit={methodAddTunjangan.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    <InputForm label="Jabatan" method={methodAddTunjangan} methodName="jabatan" />
                    <InputForm label="Nominal" method={methodAddTunjangan} methodName="nominal" />
                    <div className='flex justify-end pt-4'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default AddTunjangan