import React, { useEffect } from 'react'
import Dialog from '../../templates/Dialog'
import Button from '../Button'
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup"
import SelectorForm from '../fields/SelectorForm'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import InputForm from '../fields/InputForm'


function EditTunjangan(props: any) {
    const { tunjanganAll } = useSelector((state: RootState) => state.simulasi)
    const methodEditTunjangan: any = useForm({
        mode: "all",
        resolver: yupResolver(
            yup.object().shape({
                data: yup.array().of(yup.object().shape({
                    kode: yup.string().required("Tidak boleh kosong"),
                    nominal: yup.number().required("tidak boleh kosong")
                }))
            })
        ),
        defaultValues: { data: [{ kode: "", nominal: 0 }] }
    })
    const watchKode = methodEditTunjangan.watch("data[0].kode")
    const tunjanganOpt = tunjanganAll.map((d: any) => {
        return { value: d.kode, label: d.kode }
    })
    const { fields } = useFieldArray<any>({
        control: methodEditTunjangan.control, name: "data"
    })
    useEffect(() => {
        if (watchKode !== undefined && watchKode !== null && watchKode !== "") {
            methodEditTunjangan.setValue("data[0].nominal", tunjanganAll?.find((e: any) => e.kode === watchKode)?.nominal)
        }
    }, [watchKode])
    useEffect(()=>{
        if(props.showForm){
            methodEditTunjangan.reset()
        }
    },[props.showForm])
    return (
        <Dialog show={props.showForm} toggle={props.closeForm}>
            <div className='bg-white md:w-[40vw] w-[95vw]'>
                <div className='py-4 px-6 border-b border-slate-200'>
                    <h1 className='text-xl'>Edit Tunjangan</h1>
                </div>
                <form onSubmit={methodEditTunjangan.handleSubmit(props.submit)} className='px-6 py-7 space-y-1'>
                    {fields.map((d: any, id: number) => (
                        <div key={id}>
                            <SelectorForm label="Kode" method={methodEditTunjangan} methodName={`data[${id}].kode`} options={tunjanganOpt} />
                            <InputForm label="Nominal" method={methodEditTunjangan} methodName={`data[${id}].nominal`} />
                        </div>
                    ))}
                    <div className='flex justify-end pt-4'>
                        <Button type="submit" title="Simpan" />
                    </div>
                </form>
            </div>
        </Dialog>)
}

export default EditTunjangan